import * as React from "react"
import { useCallback, useState } from "react"
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material"

interface IProps {
  open: boolean
  onNo: () => void
  onYes: (hours: number) => void
}

/**
 * Sign off that a tech review is complete.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the sign off dialog.
 */
const SignOffTechReviewDialog: React.FunctionComponent<IProps> = (props: IProps) => {
  const { open, onNo, onYes } = props

  const [hours, setHours] = useState<string>("0.0")

  const handleHours = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setHours(event.target.value)
  }, [])

  const handleSubmit = useCallback(() => {
    onYes(Number(hours))
  }, [onYes, hours])

  return (
    <>
      <Dialog open={open} onClose={onNo}>
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Alert severity="warning">Before signing off, have you uploaded the report?</Alert>
            </Grid>
            <Grid item xs={12}>
              <Alert severity="warning">Before signing off, have you inputted the consultant score?</Alert>
            </Grid>
            <Grid item xs={8}>
              How many hours did you work?
            </Grid>
            <Grid item xs={4}>
              <TextField value={hours} onChange={handleHours} label="Hours Worked" />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container sx={{ pl: 2, pr: 2 }}>
            <Grid item xs>
              <Button onClick={onNo}>Cancel</Button>
            </Grid>
            <Grid item>
              <Button onClick={handleSubmit} autoFocus>
                Yes, I&apos;m Sure
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SignOffTechReviewDialog
