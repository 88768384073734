import { IWorkAssignment } from "./IWorkAssignment"
import { IConsultant } from "./IConsultant"

export const WORK_ASSIGNMENT_CONSULTANT_ENDPOINT = "work_assignment_consultants"
export const EXPENSE_REPORT_WORK_ASSIGNMENT_CONSULTANT_ENDPOINT = "expense_report_work_assignment_consultants"

export interface IWorkAssignmentConsultant {
  id: number
  amount: number
  accepted: boolean
  declined: boolean
  request_sent: boolean
  hourly_rate: boolean
  hours: number
  total_amount: number
  priority: number
  created: string
  updated: string
  work_assignment: IWorkAssignment
  consultant: IConsultant
  scoring_technical_quality: number
  scoring_formatting_quality: number
  scoring_responsiveness: number
  scoring_comment: string
}

/**
 * Checks if the "Tech" review type is completed.
 * Completion is defined by all scoring fields and the tech review comment being filled.
 *
 * @param {IWorkAssignmentConsultant | undefined} wac - The work assignment consultant object to check.
 * @returns {boolean} - Returns true if all "Tech" scoring criteria are completed; otherwise, false.
 */
export const isTechScoringComplete = (wac?: IWorkAssignmentConsultant):boolean => {
  return (
    wac !== undefined &&
    wac !== null &&
    wac.scoring_technical_quality > 0 &&
    wac.scoring_comment?.length > 0
  )
}